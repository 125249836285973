import { useActiveLink } from '../context/activelink';
import {
  HomeIcon,
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  CalculatorIcon,
  BanknotesIcon,
} from '@heroicons/react/24/outline';
import Link from 'next/link';

export const BottomNav = () => {
  const { activeLink, setActiveLink } = useActiveLink();

  const handleClick = (href: string) => {
    setActiveLink(href);
  };

  return (
    <div className='block fixed bottom-0 border-t dark:border-gray-800 lg:hidden left-0 z-50 w-full h-16 bg-white dark:bg-gray-900 shadow-shadow-custom'>
      <div className='grid h-full max-w-lg grid-cols-5 mx-auto font-medium'>
        <Link href='/' onClick={() => handleClick('/')} className={`inline-flex flex-col items-center justify-center px-2 group ${activeLink === '/' ? 'bg-gray-100 dark:bg-gray-800 text-blue-600 dark:text-gray-100' : 'text-gray-600 dark:text-gray-200'}`}>
          <HomeIcon className={`size-7 ${activeLink === '/' ? 'text-blue-600' : 'text-gray-600 dark:text-gray-200'}`} />
          <span className={`text-xxs ${activeLink === '/' ? 'text-blue-600 dark:text-gray-100' : 'text-gray-600 dark:text-gray-200'}`}>
            Beranda
          </span>
        </Link>
        <Link href='/cek-pajak-kendaraan-bermotor-online' onClick={() => handleClick('/cek-pajak-kendaraan-bermotor-online')} className={`inline-flex flex-col items-center justify-center px-2 group ${activeLink === '/cek-pajak-kendaraan-bermotor-online' ? 'bg-gray-100 dark:bg-gray-800 text-blue-600 dark:text-gray-100' : 'text-gray-600 dark:text-gray-200'}`}>
          <DocumentMagnifyingGlassIcon className={`size-7 ${activeLink === '/cek-pajak-kendaraan-bermotor-online' ? 'text-blue-600' : 'text-gray-600 dark:text-gray-200'}`} />
          <span className={`text-xxs ${activeLink === '/cek-pajak-kendaraan-bermotor-online' ? 'text-blue-600 dark:text-gray-100' : 'text-gray-600 dark:text-gray-200'}`}>
            Cek Pajak
          </span>
        </Link>
        <Link href='/syarat-proses-dan-cara-mutasi-balik-nama-dan-bayar-pajak-kendaraan' onClick={() => handleClick('/syarat-proses-dan-cara-mutasi-balik-nama-dan-bayar-pajak-kendaraan')} className={`inline-flex flex-col items-center justify-center px-2 group ${activeLink === '/syarat-proses-dan-cara-mutasi-balik-nama-dan-bayar-pajak-kendaraan' ? 'bg-gray-100 dark:bg-gray-800 text-blue-600 dark:text-gray-100' : 'text-gray-600 dark:text-gray-200'}`}>
          <DocumentTextIcon className={`size-7 ${activeLink === '/syarat-proses-dan-cara-mutasi-balik-nama-dan-bayar-pajak-kendaraan' ? 'text-blue-600' : 'text-gray-600 dark:text-gray-200'}`} />
          <span className={`text-xxs ${activeLink === '/syarat-proses-dan-cara-mutasi-balik-nama-dan-bayar-pajak-kendaraan' ? 'text-blue-600 dark:text-gray-100' : 'text-gray-600 dark:text-gray-200'}`}>
            Petunjuk
          </span>
        </Link>
        <Link href='/tarif-pnbp-kendaraan-bermotor' onClick={() => handleClick('/tarif-pnbp-kendaraan-bermotor')} className={`inline-flex flex-col items-center justify-center px-2 group ${activeLink === '/tarif-pnbp-kendaraan-bermotor' ? 'bg-gray-100 dark:bg-gray-800 text-blue-600 dark:text-gray-100' : 'text-gray-600 dark:text-gray-200'}`}>
          <CalculatorIcon className={`size-7 ${activeLink === '/tarif-pnbp-kendaraan-bermotor' ? 'text-blue-600' : 'text-gray-600 dark:text-gray-200'}`} />
          <span className={`text-xxs ${activeLink === '/tarif-pnbp-kendaraan-bermotor' ? 'text-blue-600 dark:text-gray-100' : 'text-gray-600 dark:text-gray-200'}`}>
            Tarif
          </span>
        </Link>
        <Link href='/cek-nilai-jual-kendaraan-bermotor-online' onClick={() => handleClick('/cek-nilai-jual-kendaraan-bermotor-online')} className={`inline-flex flex-col items-center justify-center px-2 group ${activeLink === '/cek-nilai-jual-kendaraan-bermotor-online' ? 'bg-gray-100 dark:bg-gray-800 text-blue-600 dark:text-gray-100' : 'text-gray-600 dark:text-gray-200'}`}>
          <BanknotesIcon className={`size-7 ${activeLink === '/cek-nilai-jual-kendaraan-bermotor-online' ? 'text-blue-600' : 'text-gray-600 dark:text-gray-200'}`} />
          <span className={`text-xxs ${activeLink === '/cek-nilai-jual-kendaraan-bermotor-online' ? 'text-blue-600 dark:text-gray-100' : 'text-gray-600 dark:text-gray-200'}`}>
            NJKB
          </span>
        </Link>
      </div>
    </div>
  );
};
