'use client';
import '../styles/globals.css';
import Script from 'next/script';
import Sidebar from './components/sidebar';
import { BottomNav } from './components/footer';
import { NavBar } from './components/header';
import { ActiveLinkProvider } from './context/activelink';
import React, { useEffect, useState } from 'react';

export default function RootLayout({
  children,
}: {
  children: React.ReactNode
}) {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleHeader = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const mainElement = document.getElementById('root-main');
    const containerElement = document.getElementById('root-container');
    const rowContainerElement = document.getElementById('row-container');

    const observerCallback = (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          if (mainElement) {
            mainElement.style.height = '100vh';
          }
          if (containerElement) {
            containerElement.style.height = '100%';
          }
          if (rowContainerElement) {
            rowContainerElement.style.height = '100%';
          }
        }
      }
    };

    const observer = new MutationObserver(observerCallback);

    if (mainElement) {
      observer.observe(mainElement, { attributes: true });
      mainElement.style.height = '100vh';
    }
    if (containerElement) {
      observer.observe(containerElement, { attributes: true });
      containerElement.style.height = '100%';
    }
    if (rowContainerElement) {
      observer.observe(rowContainerElement, { attributes: true });
      rowContainerElement.style.height = '100%';
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <ActiveLinkProvider>
      <main id='root-main'>
        <div id='root-container' className='h-full flex flex-col overflow-hidden shadow-sm'>
          <NavBar onMenuToggle={handleHeader} />
          <div id='row-container' className='h-full flex flex-row relative'>
            <Sidebar handleHeader={handleHeader} menuOpen={menuOpen} />
            <div className='lg:px-96 pt-16 lg:pb-5 pb-16 w-full h-full overflow-y-scroll flex flex-col text-gray-800 dark:text-gray-50 bg-primary dark:bg-gray-950'>
              {children}
            </div>
          </div>
        </div>
      </main>
      <BottomNav />
      <Script src="/serviceworker.js" />
      <Script src="/gtag/js.js?id=G-Z7BEWWEY6N" />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-Z7BEWWEY6N');
        `}
      </Script>
    </ActiveLinkProvider>
  );
}
